import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
    <Layout metaTitle="100 Years of Bacon History"
        metaDescription="For over 100 years, Wright® Brand has been sharing our hand-trimmed bacon with the world. Learn our family history & become part of the Wright Brand family."
        metaKeywords="100 Years of Bacon History"
    >

        <article class="page-section hero">
            <div class="image" ><img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/backgrounds/the_wrights.jpg'} alt="The Wrights"></img>
            </div>
            <div class="article-shadow"></div>

            <div style={{ margin: "5% 10%", position: "relative", top: "auto", bottom: "auto", right: "auto", left: "auto", fontFamily: 'IM Fell Great Primer', }}>
                <h2 class="title" style={{ color: "black", fontSize: "38px" }}>It started with a belief in a better slice of bacon.</h2>

                <p>
                </p>
                <div class="page" title="Page 3">
                    <div class="section">
                        <div class="layoutArea">
                            <div class="column">
                                <p style={{ color: "black" }}>In 1922, Egbert Eggleston, his son Fay and son-in-law Roy Wright set out to create a thicker, fuller-flavored slice of bacon. And they did it the only way they knew how: with craftsmanship, commitment and a mouth-watering family recipe. </p>
                                <p> </p>
                                <p style={{ color: "black" }}>In the back of their small grocery store in Vernon, Texas, they hand-selected every pork belly and trimmed it to perfection. They cured it, smoked each piece over real hickory wood and hand trimmed every thick slice. When they finally tasted their handiwork, they knew their careful attention to detail had paid off.&nbsp;</p>
                                <p> </p>
                            </div>
                        </div>
                    </div>
                </div>        </div>

        </article>


        <article class="page-section image-auto">
            <div class="image" style={{ height: "800px" }}><img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/backgrounds/vernon_truck.jpg'} alt="Vernon Truck"></img></div>
            <div class="article-shadow"></div>

            <div style={{ margin: "5% 10%", position: "relative", top: "auto", bottom: "auto", right: "auto", left: "auto", fontFamily: 'IM Fell Great Primer', }}>
                <h2 class="title" style={{ color: "black", fontSize: "38px" }}>Quality becomes tradition.</h2>
                <div class="page" title="Page 3">
                    <div class="section">
                        <div class="layoutArea">
                            <div class="column">
                                <p style={{ color: "black" }}>Word of their special thick-cut bacon spread and the family business quickly grew. Wright&reg; Brand meats were soon found in stores across the Southwest. Through it all, the family maintained complete control of how their bacon was made. Overseeing the process from beginning to end, they held every piece of bacon to their high standard of craftsmanship and care.&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </div>        </div>
        </article>


        <article class="page-section image-auto">
            <div class="image" class="image" style={{ height: "800px" }}><img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/backgrounds/wrightbrand_historyimage.jpg'} alt="Wright Brand History"></img></div>
            <div class="article-shadow"></div>

            <div style={{ margin: "5% 10%", position: "relative", top: "auto", bottom: "auto", right: "auto", left: "auto", fontFamily: 'IM Fell Great Primer', }}>
                <h2 class="title" style={{ color: "black", fontSize: "38px" }}> One hundred years. One way of doing things.</h2>
                <p>
                </p>
                <div class="page" title="Page 3">
                    <div class="section">
                        <div class="layoutArea">
                            <div class="column">
                                <p style={{ color: "black" }}>The tradition of making bacon with care is still part of the family, passed down through generations to the family members working there today. It&rsquo;s been decades since that first slab of bacon was trimmed in the back of that tiny grocery store, but you&rsquo;ll still find the same thick-cut, naturally smoked bacon in every package. And we&rsquo;ll keep doing it that way &mdash; with passion, pride and hard work. It may not be the fastest way to do it, or the easiest, but it&rsquo;s our way.&nbsp;</p>
                                <p>
                                </p>
                                <style>
                                </style>
                                <div style={{ color: "black" }}>That's<i> Bacon the Wright Way.&trade;</i></div> <br />
                                <br />
                                <p> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>


        <article class="page-section ">
            <div class="image"><img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/backgrounds/homehistory.jpg'} alt="Wright Brand History"></img>

            </div>
        </article>

        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon" />
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default SecondPage
